body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

body #root {
  width: 100vw;
  height: 100vh;
}

@font-face {
  font-family: 'Calibri-Regular';
  src: url('../assets/fonts/Calibri/Calibri-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri-Bold';
  src: url('../assets/fonts/Calibri/Calibri-Bold.ttf');
  font-style: bold;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri-Light';
  src: url('../assets/fonts/Calibri/Calibri-Light.ttf');
  font-style: thin;
  font-weight: 300;
  font-display: swap;
}
